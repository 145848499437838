import React from 'react';
import { Row, TableBodyPropGetter, TableBodyProps } from 'react-table';

import Skeleton from 'react-loading-skeleton';

import { TBody } from './styles';

import { IEncomendas } from '@/dtos/pages/encomendas';

interface ITableHeadProps {
  encomendas: IEncomendas[];
  page: Row<any>[];
  prepareRow: (row: Row<any>) => void;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
}

const TableHead: React.FC<ITableHeadProps> = ({
  encomendas,
  page,
  getTableBodyProps,
  prepareRow,
}) => {
  return (
    <TBody {...getTableBodyProps()}>
      {encomendas.length === 0
        ? [0, 1, 2, 3].map((index) => (
            <tr key={index}>
              {[0, 1, 2, 3].map((index) => (
                <td key={index}>
                  <p>
                    <Skeleton style={{ maxWidth: 100, minWidth: 70 }} />
                  </p>
                </td>
              ))}
            </tr>
          ))
        : page.map((row, rowMapIndex) => {
            prepareRow(row);

            return (
              <tr key={row.id + rowMapIndex.toString()} {...row.getRowProps()}>
                {row.cells.map((cell, cellMapIndex) => (
                  <td
                    key={cell.value + cellMapIndex.toString()}
                    {...cell.getCellProps()}
                  >
                    <p>{cell.render('Cell')}</p>
                  </td>
                ))}
              </tr>
            );
          })}
    </TBody>
  );
};

export default TableHead;
