import styled from 'styled-components';

export const TableSection = styled.section`
  display: flex;

  justify-content: flex-start;

  width: 100%;
  max-width: 1380px;
`;

export const Container = styled.div`
  margin-top: 23.85px;

  width: 100%;
  max-width: 984px;

  table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;

    // apesar de não colocar o estilo no <td> a table aplica nele também
    tr th:nth-child(3) {
      width: 40%;

      @media (max-width: 499px) {
        width: 60%;
      }

      @media (max-width: 425px) {
        width: 45%;
      }
    }

    th:nth-child(3),
    td:nth-child(3) {
      padding-left: 20px;

      @media (max-width: 499px) {
        padding-left: 8px;
      }

      @media (max-width: 410px) {
        padding-left: 0px;
      }
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 10px;

  button {
    padding: 5px 10px;
    border: 0;
    border-radius: 8px;
  }

  button + button {
    margin: 5px;
  }

  span {
    padding: 5px;
  }

  select {
    background: #e0e0e0;
    margin-left: auto;

    border: 0;
  }
`;
