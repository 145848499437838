import React, { useMemo } from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table';
import { navigate } from 'gatsby';

import TableHead from './TableHead';
import TableBody from './TableBody';

import SearchIcon from '@/images/icons/icon-search.svg';

import { TableSection, Container, PaginationContainer } from './styles';

import { IEncomendas } from '@/dtos/pages/encomendas';

interface EncomendasTableProps {
  encomendas: IEncomendas[];
  cpf: string;
}

const EncomendasTable: React.FC<EncomendasTableProps> = ({
  encomendas,
  cpf,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Nota fiscal',
        accessor: 'notaFiscal',
      },
      {
        Header: 'Local/data',
        accessor: 'locale',
      },
      {
        Header: 'Situação',
        accessor: 'description',
      },
      {
        Header: ' ',
        accessor: 'showMore',
      },
    ],

    []
  );

  const data: Array<any> = useMemo(
    () =>
      encomendas?.map((nota) => ({
        notaFiscal: nota.notaFiscal,
        locale: `${nota.unidade}, ${nota.data} - ${nota.hora}`,
        description: nota.descricao,
        showMore: (
          <button
            type="button"
            onClick={() =>
              navigate(
                `/rastreamento/resultado?cpf=${cpf}&notaFiscal=${nota.notaFiscal}`
              )
            }
          >
            <img src={SearchIcon} alt="Ícone de lupa de busca." />
            Rastrear
          </button>
        ),
      })),
    [encomendas, cpf]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
    page,
  } = useTable(
    {
      columns,
      data,
    },
    usePagination,
    useRowSelect
  );

  return (
    <TableSection>
      <Container>
        <table {...getTableProps()}>
          <TableHead headerGroups={headerGroups} encomendas={encomendas} />

          <TableBody
            encomendas={encomendas}
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
          />
        </table>

        {pageOptions && pageOptions.length > 1 && (
          <PaginationContainer>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>

            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>

            <span>
              Página
              <strong>
                {' '}
                {pageIndex + 1} de {pageOptions.length}
              </strong>
            </span>
          </PaginationContainer>
        )}
      </Container>
    </TableSection>
  );
};

export default EncomendasTable;
