import styled from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

export const EncomendasWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 100vw;

  height: 100%;
  min-height: 500px;
`;

export const EncomendasSection = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0px 30px 253px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1114px) {
    justify-content: center;
  }
`;

const SectionWrapper = styled.section`
  display: flex;

  justify-content: flex-start;

  width: 100%;
  max-width: 1380px;
`;

export const BreadCrumbsContainer = styled(SectionWrapper)`
  margin: 40px 0px;

  @media (max-width: 650px) {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  max-width: 1380px;

  svg {
    transition: color 0.3s;

    :hover {
      cursor: pointer;
      color: ${colors.blue400};
    }
  }

  h1 {
    display: flex;
    align-items: center;

    font-weight: bold;
    font-size: 24px;
    color: #000;

    line-height: 29px;

    @media (max-width: 400px) {
      max-width: 330px;
    }
  }

  p {
    display: block;
    margin-top: 6.15px;

    color: #000;

    font-size: 22px;

    @media (max-width: 499px) {
      font-size: 20px;
    }

    @media (max-width: 320px) {
      font-size: 17px;
    }
  }
`;
