import React, { useMemo } from 'react';
import { navigate } from 'gatsby';
import { FaWhatsapp } from 'react-icons/fa';
import encodeUrl from 'encodeurl';

import { SUPPORT_NUMBER } from '@/config';

import ErrorImage from '@/images/paginas/rastreamento/resultado/errorPage/error-image.svg';

import { Container, SupportLink } from './styles';

interface IErrorPageProps {
  cpf: string;
  errorMessage: string;
}

const ErrorPage: React.FC<IErrorPageProps> = ({ errorMessage, cpf }) => {
  const whatsappSupportLink = useMemo(() => {
    let encodedSupportMessage = '';

    if (cpf) {
      const wppMessage = `Olá, tentei rastrear minha encomenda no app e ocorreu um erro. Meu CPF é ${cpf}. Pode me ajudar? `;

      encodedSupportMessage = encodeUrl(wppMessage);
    } else {
      encodedSupportMessage = encodeUrl(
        'Olá, preciso de ajuda para rastrear minha encomenda.'
      );
    }

    return `https://api.whatsapp.com/send?phone=${SUPPORT_NUMBER}&text=${encodedSupportMessage}`;
  }, [cpf]);

  return (
    <Container>
      <img src={ErrorImage} alt="Alert error" />

      <p>{errorMessage || 'Erro interno do servidor.'}</p>

      <button type="button" onClick={() => navigate('/rastreamento')}>
        Voltar
      </button>

      <SupportLink href={whatsappSupportLink} target="_blank" rel="noreferrer">
        <FaWhatsapp />
        Suporte via WhatsApp
      </SupportLink>
    </Container>
  );
};

export default ErrorPage;
