import React from 'react';
import { HeaderGroup } from 'react-table';

import Skeleton from 'react-loading-skeleton';

import { THead } from './styles';

import { IEncomendas } from '@/dtos/pages/encomendas';

interface ITableHeadProps {
  headerGroups: HeaderGroup<any>[];
  encomendas: IEncomendas[];
}

const TableHead: React.FC<ITableHeadProps> = ({ headerGroups, encomendas }) => {
  return (
    <THead>
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, index) => (
            <th key={column.id} {...column.getHeaderProps()}>
              {encomendas.length === 0 ? (
                <>{index < 3 && <Skeleton style={{ maxWidth: 70 }} />}</>
              ) : (
                column.render('Header')
              )}
            </th>
          ))}
        </tr>
      ))}
    </THead>
  );
};

export default TableHead;
