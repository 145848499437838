import styled from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

export const TBody = styled.tbody`
  tr {
    margin: 0;
    border-bottom: 1px solid #e0e0e0;
  }

  td {
    padding: 12px 0;
    font-size: 14px;

    &:nth-child(1) {
      padding-left: 16px;
    }

    &:nth-child(2) {
      @media (max-width: 499px) {
        display: none;
      }
    }

    &:nth-child(3) {
      font-size: 10px;

      @media (max-width: 720px) {
        p {
          max-width: 200px;
        }
      }

      @media (max-width: 620px) {
        font-size: 9px;

        p {
          max-width: 150px;
        }
      }

      @media (max-width: 499px) {
        p {
          max-width: 200px;
        }
      }

      @media (max-width: 425px) {
        p {
          max-width: 180px;
        }
      }

      @media (max-width: 460px) {
        font-size: 9px;
        padding-left: 4px;
      }

      @media (max-width: 380px) {
        p {
          max-width: 110px;
        }
      }
    }

    &:nth-child(4) {
      p {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @media (max-width: 620px) {
      font-size: 11px;
    }

    @media (max-width: 460px) {
      font-size: 10px;
    }
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 4px 12px;
    width: 110px;

    background-color: ${colors.blue400};
    color: ${colors.white};

    border-radius: 8px;
    border: none;

    font-size: 14px;

    img {
      width: 13px;
      height: 13px;
    }

    @media (max-width: 530px) {
      font-size: 10px;
      width: 85px;

      img {
        width: 8px;
        height: 8px;
      }
    }

    @media (max-width: 425px) {
      font-size: 10px;
      width: 80px;

      img {
        width: 8px;
        height: 8px;
      }
    }

    :hover {
      background-color: ${colors.blue500};
    }
  }
`;
