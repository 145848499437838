import styled from 'styled-components';

export const THead = styled.thead`
  background: #e0e0e0;

  th {
    padding: 12px 0;
    text-align: start;

    &:nth-child(1) {
      padding-left: 16px;

      @media (max-width: 620px) {
        padding-left: 8px;
      }
    }

    &:nth-child(2) {
      @media (max-width: 499px) {
        display: none;
      }
    }

    &:nth-child(3) {
      @media (max-width: 460px) {
        padding-left: 4px;
      }
    }

    &:nth-child(4) {
      text-align: center;
    }

    @media (max-width: 620px) {
      font-size: 12px;

      padding: 8px 0;
    }

    @media (max-width: 460px) {
      font-size: 12px;
    }
  }
`;
