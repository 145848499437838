import React, { useEffect, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import axios from 'axios';

import SEO from '@/components/SEO';
import Layout from '@/components/Layout';
import ErrorPage from '@/components/Pages/Encomendas/ErrorPage';
import BreadCrumbs from '@/components/BreadCrumbs';
import EncomendasTable from '@/components/Pages/Encomendas/EncomendasTable';

import { IEncomendas } from '@/dtos/pages/encomendas';

import {
  EncomendasWrapper,
  EncomendasSection,
  BreadCrumbsContainer,
  TitleContainer,
} from '@/styles/pages/rastreamento/encomendas';

const pageBreadCrumbs = [
  { name: 'Página inicial', relativeURL: '/' },
  { name: 'Rastreamento', relativeURL: '/rastreamento' },
  {
    name: 'Encomendas',
  },
];

const Encomendas: React.FC = () => {
  const [cpf] = useQueryParam<string>('cpf');

  const [encomendas, setEncomendas] = useState<IEncomendas[]>([]);

  const [showErrorPage, setShowErrorPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadEncomendas = async () => {
      if (cpf) {
        try {
          const response = await axios.post(
            '/api/rastreamento/cpf/notas',
            {
              cpf,
            },
            {
              headers: {
                dominio: 'GEN',
                usuario: 'chatbot',
                senha: 'cbgen21',
              },
            }
          );

          setEncomendas(response.data);
        } catch (error: any) {
          setShowErrorPage(true);

          if (error.message || error.response?.data.message) {
            setErrorMessage(error.response?.data.message || error.message);
          }
        }
      }
    };

    loadEncomendas();
  }, [cpf]);

  return (
    <>
      <SEO
        pageTitle={`Rastreamento | Encomendas`}
        title={`Rastreamento | Encomendas`}
        description="Página de encomendas"
        image="/images/components/SEO/og.png"
      />

      <Layout>
        <EncomendasWrapper>
          <EncomendasSection>
            {showErrorPage && (
              <ErrorPage cpf={cpf} errorMessage={errorMessage} />
            )}

            {!showErrorPage && (
              <>
                <BreadCrumbsContainer>
                  <BreadCrumbs pageBreadCrumbs={pageBreadCrumbs} />
                </BreadCrumbsContainer>

                <TitleContainer>
                  {encomendas.length === 0 ? (
                    <>
                      <h1>⏳ Aguarde um momento</h1>
                      <p>Estamos buscando as suas encomendas...</p>
                    </>
                  ) : (
                    <>
                      <h1>Encomendas encontradas: {encomendas.length}</h1>
                      <p>Qual você gostaria de rastrear?</p>
                    </>
                  )}
                </TitleContainer>

                <EncomendasTable encomendas={encomendas} cpf={cpf} />
              </>
            )}
          </EncomendasSection>
        </EncomendasWrapper>
      </Layout>
    </>
  );
};

export default Encomendas;
